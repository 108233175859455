import { preloadWidgetData } from '@hmn/rtl-web-core/helpers/layout-builder/preload-widget-data'
import { serializeObject } from '@hmn/rtl-web-core/helpers/serialize'
import { createDataClient } from '@hmn/rtl-web-core/hooks'
import { adsGetServerSidePropsFactory } from '@hmn/rtl-web-core/next/data/adsGetServerSideProps'
import { navigationGetServerSideProps } from '@hmn/rtl-web-core/next/data/navigationGetServerSideProps'
import { sessionGetServerSideProps } from '@hmn/rtl-web-core/next/data/sessionGetServerSideProps'
import combineGetServerSideProps from '@hmn/rtl-web-core/next/helpers/combineGetServerSideProps'

import { listingInsertAds as insertAdsConfig, staticAds } from '@hmn/rtl-zena-ui/components/Ad/config/general'

import { componentGetDataMap, ComponentType } from '../components/LayoutBuilder/LayoutBuilder.component'
import { Category } from '../components/Layouts/CategoryLayout'
import { authOptions } from './api/auth/[...nextauth]'

const { outOfPage1, outOfPage3, anchor } = staticAds

export default function Home({ ...props }) {
    return <Category {...props} />
}

export const categoryGetServerSideProps = async ({ req }) => {
    const dataClient = createDataClient({ req })
    const { getData } = dataClient

    const { data: category } = await getData({
        resource: `category/find`,
        params: {
            query: {
                id: process.env.NEXT_PUBLIC_HOMEPAGE_ID_ZENA
            }
        }
    })

    const categoryDataResult = await getData({
        resource: `category/${category.id}/layout`
    })

    const categoryLayout = {
        ...categoryDataResult.data,
        widgetList: await preloadWidgetData(
            categoryDataResult.data.widgetList,
            componentGetDataMap,
            ComponentType,
            categoryDataResult.data.entityList
        )
    }

    return {
        props: {
            category: serializeObject(category),
            categoryLayout: serializeObject(categoryLayout),
            categoryEntities: null,
            isLayout: true,
            isListing: false,
            slugs: [],
            gemiusId: category.extended_attributes?.gemius_id || process.env.NEXT_PUBLIC_GEMIUS_ID_ZENA
        }
    }
}

export const getServerSideProps = combineGetServerSideProps([
    categoryGetServerSideProps,
    sessionGetServerSideProps(authOptions, { maxAge: 15, sMaxAge: 30, staleWhileRevalidate: 3600 }),
    adsGetServerSidePropsFactory({
        pageType: 'category',
        zones: { outOfPage1, outOfPage3, anchor },
        insertAdsConfig,
        pageId: process.env.NEXT_PUBLIC_HOMEPAGE_ID_ZENA
    }),
    navigationGetServerSideProps
])
